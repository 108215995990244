import { getPromos } from '../core/modules/getPromos';
import { Deal } from '../core/types/Deal';
import { LabelPromo } from '../types/LabelPromo';

export enum PromoType {
  ALLOWED = 'allowed',
  FLAGS = 'flags',
  VOUCHER = 'voucher',
  EXCLUSIVE = 'exclusive',
  VERIFIED = 'verified',
}

export const getPromoItems = (
  deal: Partial<Deal>,
  type: string,
  showLabels: boolean,
): LabelPromo[] => {
  const items: LabelPromo[] = getPromos(deal, type);

  if (showLabels) {
    return items.concat(
      (deal?.labels_formatted || []).map((label) => {
        return {
          type: label.type,
          value: label.display_value,
          display_value:
            label.display_value && label.display_value.length > 18
              ? `${label.display_value.substring(0, 18)}...`
              : label.display_value,
        };
      }),
    );
  }

  return items;
};
