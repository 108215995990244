import { Attribute } from '../../types/Attribute';
import getGoogleClickId from '../../utils/getGoogleClickId';

import BaseFormatter from './BaseFormatter';

export default class MozoFormatter extends BaseFormatter {
  getCustomAttributes(): Attribute[] {
    return [
      {
        name: 'dimension8',
        getValue: this.getArticleId.bind(this),
      },
      {
        name: 'dimension9',
        getValue: this.getMatchIds.bind(this),
      },
      {
        name: 'dimension10',
        getValue: this.getMerchantNames.bind(this),
      },
      {
        name: 'dimension11',
        getValue: this.getIndex.bind(this),
      },
      {
        name: 'dimension12',
        getValue: this.getTotalDeals.bind(this),
      },
      {
        name: 'dimension13',
        getValue: this.getFormattedRetailPrices.bind(this),
      },
      {
        name: 'dimension15',
        getValue: this.getServiceProviders.bind(this),
      },
      {
        name: 'dimension16',
        getValue: this.getProductNames.bind(this),
      },
      {
        name: 'dimension17',
        getValue: this.getProductTypes.bind(this),
      },
      {
        name: 'dimension19',
        getValue: this.getBackgroundColor.bind(this),
      },
      {
        name: 'dimension20',
        getValue: this.getCustomTrackingIds.bind(this),
      },
      {
        name: 'dimension21',
        getValue: this.getLabelsAndPromos.bind(this),
      },
      {
        name: 'dimension22',
        getValue: this.getUrls.bind(this),
      },
      {
        name: 'dimension23',
        getValue: this.getVoucherCodeString.bind(this),
      },
      {
        name: 'dimension24',
        getValue: this.getElementIds.bind(this),
      },
      {
        name: 'dimension25',
        getValue: this.getMerchantIds.bind(this),
      },
      {
        name: 'dimension26',
        getValue: this.getStartDate.bind(this),
      },
      {
        name: 'dimension27',
        getValue: this.getClickType.bind(this),
      },
      {
        name: 'dimension28',
        getValue: this.getReviewCodes.bind(this),
      },
      {
        name: 'dimension29',
        getValue: this.getReviewCounts.bind(this),
      },
      {
        name: 'dimension30',
        getValue: this.getReviewScores.bind(this),
      },
      {
        name: 'dimension31',
        getValue: getGoogleClickId,
      },
      {
        name: 'dimension32',
        getValue: this.getWasPrices.bind(this),
      },
      {
        name: 'dimension33',
        getValue: this.getEndDate.bind(this),
      },
      {
        name: 'metric1',
        getValue: this.getLoadTime.bind(this),
      },
      {
        name: 'metric24',
        getValue: this.getViewportTime.bind(this),
      },
      {
        name: 'metric25',
        getValue: this.getTimeRemaining.bind(this),
      },
      {
        name: 'metric27',
        getValue: this.getPageHeight.bind(this),
      },
      {
        name: 'metric28',
        getValue: this.getElementYPosition.bind(this),
      },
    ];
  }
}
