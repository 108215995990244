import { RequestType } from '../../../../core/types/PostJSON';
import type { EventBattle } from '../../../../types/Battle';
import generateNumber from '../../../../utils/generateNumber';
import getUrlParameters from '../../../../utils/getUrlParameters';
import { postJSON } from '../../ajax';

import type { AnalyticsHawkAppeared } from './model';
import { sendAppearedEvent } from './sendAppearedEvent';

export const analyticsHawkAppeared = ({
  processedWidgets,
  battle,
  platformData,
  siteConfig,
}: AnalyticsHawkAppeared): void => {
  const { metrics } = getUrlParameters(['metrics']);
  const sendMetrics = ((): boolean => {
    if (metrics === 'disabled') {
      return false;
    }
    if (typeof metrics !== 'undefined' && parseInt(metrics, 10)) {
      return true;
    }
    return generateNumber(100) === 0;
  })();

  if (sendMetrics) {
    // Don't wait for the result of saving metrics
    postJSON<RequestType.SEND_METRICS_REQUEST>(`${process.env.METRICS_ENDPOINT}/send_metrics`, {
      platform: platformData.platform,
      load_time: parseInt(performance.now().toFixed(0), 10),
      type: 'widgets',
      es6: 1,
    });
  }
  // Send unified appear event when all widgets are processed
  sendAppearedEvent(
    platformData.articleId,
    processedWidgets || [],
    battle as EventBattle,
    siteConfig && siteConfig.ga,
  );
};
