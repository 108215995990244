import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/tg.css';

loadCSS('tg-site');

export const TgSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default TgSiteWrapper;
