import { AnalyticsData } from '../types/Analytics';
import { FreyrData } from '../types/Freyr';

import BaseFormatter from './formats/BaseFormatter';

export default (data: AnalyticsData): FreyrData => {
  const Formatter = new BaseFormatter(false);

  return {
    category: Formatter.getEventCategory(data),
    affiliate: {
      action: {
        type: Formatter.getClickType(data),
        id: Formatter.getElementIds(data).join('|'),
        event: Formatter.getEventPrefix(data) || Formatter.getEventType(data),
      },
      component: {
        flag: Formatter.getEventFlag(data),
        product: Formatter.getEventProductType(data),
        category: Formatter.getEventComponentName(data),
        type: Formatter.getEventComponentCategory(data),
        label: Formatter.getEventLabel(data),
        index: Formatter.getIndex(data),
        linkCount: Formatter.getTotalDeals(data),
        battleId: Formatter.getBattleData(data),
        yPosition: Formatter.getElementYPositionAsNumber(data),
        pageHeight: Formatter.getPageHeightAsNumber(),
        backgroundColor: Formatter.getBackgroundColor(data),
      },
      timing: {
        loadTime: Formatter.getLoadTimeAsNumber(),
        viewportTime: Formatter.getViewportTime(data),
      },
    },
    products: Formatter.getProducts(data).map((_, index) => {
      return {
        product: {
          primary: {
            id: Formatter.getMatchIds(data)[index],
            name: Formatter.getProductNames(data)[index],
            type: Formatter.getProductTypes(data)[index],
            price: Formatter.getRetailPrices(data)[index],
            previousPrice: Formatter.getWasPrices(data)[index],
            currency: Formatter.getCurrencyIso(data)[index],
            preorder: Formatter.getPreorder(data)[index],
            labels: Formatter.getLabels(data)[index],
            promos: Formatter.getPromos(data)[index],
            linkDestination: Formatter.getUrls(data)[index],
            serviceProvider: Formatter.getServiceProviders(data)[index],
            revenueId: Formatter.getCustomTrackingIds(data)[index],
            startDate: Formatter.getStartDate(data)[index],
            endDate: Formatter.getStartDate(data)[index],
            timeRemaining: Formatter.getTimeRemaining(data),
            voucherCodeString: Formatter.getVoucherCodeString(data),
            scope: Formatter.getScope(data)[index],
          },
          source: 'hawk',
        },
        merchant: {
          id: Formatter.getMerchantIds(data)[index],
          name: Formatter.getMerchantNames(data)[index],
          url: Formatter.getMerchantUrls(data)[index],
          network: Formatter.getMerchantAffiliateNetwork(data)[index],
        },
        model: {
          id: Formatter.getModelIds(data)[index],
          brand: Formatter.getModelBrands(data)[index],
          name: Formatter.getModelNames(data)[index],
          parent: Formatter.getModelParentNames(data)[index],
        },
      };
    }),
    reviews: Formatter.getReviews(data).map((review) => {
      const reviewData = {
        ...data,
        reviews: Formatter.getReviews(data).filter((r) => r.code === review.code),
      };

      return {
        code: Formatter.getReviewCodes(reviewData)[0],
        count: Formatter.getReviewCounts(reviewData)[0],
        score: Formatter.getReviewScores(reviewData)[0],
      };
    }),
  };
};
