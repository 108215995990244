import { sendGAEvent, sendFreyrEvent } from '../../../../analytics-library/entry';
import type { GoogleAnalyticsConfig } from '../../../../core/modules/sites/types/Site';
import { AnalyticsData } from '../../../../types/Analytics';
import type { EventBattle } from '../../../../types/Battle';
import type { IProcessedWidget } from '../../renderWidget/updateProcessedWidgets';

export const sendAppearedEvent = (
  articleId: string | null,
  processedWidgets: IProcessedWidget[],
  battle: EventBattle,
  gaConfig: GoogleAnalyticsConfig | null,
): void => {
  if (processedWidgets) {
    const eventLabel = processedWidgets.reduce((acc, widget) => {
      if (acc[widget.widgetType]) {
        return {
          ...acc,
          [widget.widgetType]: acc[widget.widgetType] + 1,
        };
      }

      return {
        ...acc,
        [widget.widgetType]: 1,
      };
    }, {});

    const gaData = {
      event: {
        category: 'Affiliates',
        type: 'Hawk appeared',
        label: JSON.stringify(eventLabel),
        articleId,
        battle,
        totalDeals: processedWidgets?.length ?? 0,
      },
    };

    if (gaConfig) {
      sendGAEvent(gaData as AnalyticsData, gaConfig, null, false, false);
    }
    sendFreyrEvent(gaData as AnalyticsData, false);
  }
};
