import BaseFormatter from './formats/BaseFormatter';
import CinemaBlend from './formats/CinemaBlend';
import GetPriceFormatter from './formats/GetPriceFormatter';
import MozoFormatter from './formats/MozoFormatter';
import PricePandaIdThFormatter from './formats/PricePandaIdThFormatter';
import PricePandaSgMyPhFormatter from './formats/PricePandaSgMyPhFormatter';
import ShortlistFormatter from './formats/ShortlistFormatter';
import TIFormatter from './formats/TIFormatter';
import VanillaFormatter from './formats/VanillaFormatter';
import WhoWhatWearFormatter from './formats/WhoWhatWearFormatter';

export default (format: string | null, flattenEmptyValues: boolean): BaseFormatter => {
  switch (format) {
    case 'ti':
      return new TIFormatter(flattenEmptyValues);
    case 'getprice':
      return new GetPriceFormatter(flattenEmptyValues);
    case 'pricepandasgmyph':
      return new PricePandaSgMyPhFormatter(flattenEmptyValues);
    case 'pricepandaidth':
      return new PricePandaIdThFormatter(flattenEmptyValues);
    case 'mozo':
      if (typeof window !== 'undefined' && window.MZ && window.MZ.cmsPageId) {
        if (!window.analytics_ga_data) {
          window.analytics_ga_data = {};
        }
        window.analytics_ga_data.dimension8 = window.MZ.cmsPageId;
      }
      return new MozoFormatter(flattenEmptyValues);
    case 'cinemablend':
      return new CinemaBlend(flattenEmptyValues);
    case 'whowhatwear':
      return new WhoWhatWearFormatter(flattenEmptyValues);
    case 'shortlist':
      return new ShortlistFormatter(flattenEmptyValues);
    default:
      // Default formatter is Vanilla
      return new VanillaFormatter(flattenEmptyValues);
  }
};
