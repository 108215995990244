import React, { useEffect } from 'react';

import { httpPostRewardActivityProcessorUserReward } from '../../../../../../sharedModules/http/reward/userReward';
import { ProductTypes } from '../../../../../../types/ProductType';
import { SiteWithPlatform } from '../../../../../modules/sites/types/Site';
import { Deal } from '../../../../../types/Deal';
import { Translate } from '../../../../../types/Localiser';
import { VoucherCode } from '../../../HawkWidget/model';
import { isSignupFirstDeal } from '../modules/signupFirst';

import { Error } from './Error';
import { Loading } from './Loading';
import { getActions } from './reducer/getActions';
import { reducer, getInitialState } from './reducer/reducer';
import { Signup } from './Signup';
import { VultureReveal } from './VultureReveal';

type VulturePopupProps = {
  popupDeal: Deal;
  setPopupDeal: (deal: Deal | null) => void;
  uniqueCodeData: string;
  voucherCode: VoucherCode;
  setUniqueCodeData: (value: string) => void;
  site: SiteWithPlatform;
  translate: Translate;
  setRevealedPopupMatchId: (value: number) => void;
};

export const VulturePopup: React.FC<VulturePopupProps> = ({
  popupDeal,
  setPopupDeal,
  uniqueCodeData,
  setUniqueCodeData,
  voucherCode,
  site,
  translate,
  setRevealedPopupMatchId,
}: VulturePopupProps) => {
  const isReward = popupDeal.product_type === ProductTypes.rewards;
  const isSignupFirst = isSignupFirstDeal(popupDeal);

  const [state, dispatch] = React.useReducer(reducer, getInitialState());

  const actions = getActions(dispatch, translate);

  useEffect(() => {
    if (isSignupFirst && state.isSessionLoaded === false) {
      actions.getSession();
    }
  }, [isSignupFirst]);

  useEffect(() => {
    if (state.user?.sub && state.user?.email && isReward)
      httpPostRewardActivityProcessorUserReward(
        state.user.sub,
        state.user.email,
        site,
        state.sessionId,
        popupDeal.product_key,
      );
  }, [state.user, site, state.sessionId, popupDeal.product_key]);

  useEffect(() => {
    if ((isSignupFirst && state.user) || !isSignupFirst) {
      setRevealedPopupMatchId(popupDeal.match_id);
    }
  }, [popupDeal.match_id, state.user, isSignupFirst]);

  if (isSignupFirst && !state.user) {
    if (!state.isSessionLoaded) {
      return <Loading />;
    }

    return (
      <Signup
        actions={actions}
        email={state.signupForm.email}
        areTermsAgreed={state.signupForm.terms}
        isLoading={state.signupForm.isLoading}
        error={state.signupForm.error}
        popupDeal={popupDeal}
        site={site}
        translate={translate}
        setPopupDeal={setPopupDeal}
      />
    );
  }

  const isMerchantNameAvailable = popupDeal.merchant && popupDeal.merchant.name;
  if (isMerchantNameAvailable) {
    if (isReward) {
      // We should always have the user object by this point,
      // however there's a chance the session id is missing.
      // This check aids in typing and catching this edge case
      if (!state.sessionId || !state.user) {
        return <Error errorText={translate('missingKiosqSessionError')} />;
      }
    }

    return (
      <VultureReveal
        popupDeal={popupDeal}
        site={site}
        translate={translate}
        setPopupDeal={setPopupDeal}
        uniqueCodeData={uniqueCodeData}
        voucherCode={voucherCode}
        setUniqueCodeData={setUniqueCodeData}
      />
    );
  }

  return null;
};
