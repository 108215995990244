import { AnalyticsData } from '../types/Analytics';
import { GAData } from '../types/GA';

import BaseFormatter from './formats/BaseFormatter';
import getFormatter from './getFormatter';

const getDataWithValues = (data: GAData): GAData => {
  for (const key in data) {
    if (!data[key] && data[key] !== 0) {
      delete data[key];
    }
  }

  return data;
};

const formatData = (data: AnalyticsData, formatter: BaseFormatter): GAData => {
  if (formatter) {
    return formatter.getFormattedData(data);
  }
  return {};
};

export default (
  data: AnalyticsData | null,
  format: string | null,
  el: HTMLElement | null,
  flattenEmptyValues: boolean,
): GAData => {
  // We attempt to recycle some data from the page where Hawk loads
  const pageData =
    typeof window !== 'undefined' && window.analytics_ga_data ? window.analytics_ga_data : {};

  const Formatter = getFormatter(format, flattenEmptyValues);
  const attributes = Formatter.getAttributes();

  // Capture the data attributes if the attribute is a recognised GA attribute name
  const elData = attributes.reduce((acc, attribute) => {
    if (typeof el?.dataset[attribute.name] !== 'undefined') {
      acc[attribute.name] = el.dataset[attribute.name];
    }

    return acc;
  }, {});

  if (!data) {
    data = {
      event: {
        category: '',
        flag: '',
        component: {
          name: '',
          category: '',
        },
        label: '',
        elementIds: [],
        prefix: '',
        productType: '',
        type: '',
        clickType: '',
        index: null,
        totalDeals: 0,
        viewportTime: 0,
        battle: null,
        articleId: '',
        backgroundColor: '',
        elementYPosition: 0,
        paywallStatus: false,
      },
      products: [],
      reviews: [],
    };
  }

  return {
    ...getDataWithValues(pageData),
    ...formatData(data, Formatter),
    ...getDataWithValues(elData),
  };
};
