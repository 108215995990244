import React from 'react';

import { SiteWithPlatform } from '../../../../../../modules/sites/types/Site';
import { Deal } from '../../../../../../types/Deal';
import { Translate } from '../../../../../../types/Localiser';
import { DialogOverlay } from '../../../../DialogOverlay';
import { useHawkWidgetContext } from '../../../../HawkWidget/HawkWidgetProviderContext';
import { VultureTermsConditions } from '../../../TermsConditions';
import { Actions } from '../types';

import styles from './styles/signup.css';

interface SignupProps {
  actions: Actions;
  isLoading: boolean;
  areTermsAgreed: boolean;
  email: string;
  error: string;
  popupDeal: Deal;
  site: SiteWithPlatform;
  translate: Translate;
  setPopupDeal: (deal: Deal | null) => void;
}

export const Signup: React.FC<SignupProps> = ({
  actions,
  isLoading,
  areTermsAgreed,
  email,
  error,
  popupDeal,
  site,
  translate,
  setPopupDeal,
}) => {
  const {
    genericSharedComponents: { DealImage, DisplayName },
  } = useHawkWidgetContext();

  const submitClassName = [styles.submit, isLoading ? styles['submit-loading'] : '']
    .join(' ')
    .trim();
  const submitText = isLoading ? '' : translate('signupCTA');

  const closeDialogHandler = (): void => {
    setPopupDeal(null);
  };

  const emailChangeHandler = (e): void => {
    const { value } = e.target;
    actions.inputUpdate({ email: value });
  };

  const termsChangeHandler = (e): void => {
    const { checked } = e.target;
    actions.inputUpdate({ terms: checked });
  };

  const onSubmitHandler = (event): void => {
    event.preventDefault();
    if (isLoading) {
      return;
    }

    actions.signupUser(site.name, areTermsAgreed, email);
  };

  return (
    <DialogOverlay closeDialog={closeDialogHandler}>
      <div className={styles.container}>
        <div className={styles.main}>
          <DealImage
            deal={popupDeal}
            priority={['merchant']}
            className="vulture"
            width={120}
            height={95}
          />
          <DisplayName deal={popupDeal} type="product" className={styles.title} />
          <p className={styles.subtitle}>{translate('signupSubtitle')}</p>
          <form className={styles.form} onSubmit={onSubmitHandler}>
            <input
              className={styles.email}
              name="email"
              type="email"
              placeholder={translate('signupEmailPlaceholder')}
              required
              onChange={emailChangeHandler}
              value={email}
            />
            <div className={styles['terms-container']}>
              <input
                type="checkbox"
                name="terms"
                title="Terms and conditions consent"
                className={styles.checkbox}
                required
                onChange={termsChangeHandler}
                checked={areTermsAgreed}
              />
              <label htmlFor="terms">
                Please tick this box to confirm you&apos;ve read and agree to our{' '}
                <a href="/conditions-of-use">Terms & Conditions</a> and{' '}
                <a href="/privacy">Privacy Policy</a>
              </label>
            </div>
            <button className={submitClassName} type="submit">
              {submitText}
            </button>
            {error ? <p className={styles.error}>{error}</p> : null}
          </form>
        </div>
        {popupDeal.offer && popupDeal.offer.product_note && (
          <div className={styles.terms}>
            <VultureTermsConditions deal={popupDeal} />
          </div>
        )}
      </div>
    </DialogOverlay>
  );
};
