import { generateDataHash } from '../../analytics-library/entry/getCustomTrackingId';
import { getAllFeatures, getLabelDatasetParams } from '../../core/modules/features';
import getUrlParameters from '../../utils/getUrlParameters';

import { getNodes } from './getNodes';
import type { BuildCallbackConfig, UnbuiltPlaceholders } from './hawk/processWidgets/model';
import preparePlaceholders from './preparePlaceholders';
import setHawkFallbackId from './setHawkFallbackId';

const getCCDataset = async (el: HTMLElement): Promise<Record<string, string>> => {
  const params = getUrlParameters();

  const dataset = getAllFeatures().reduce((result: Record<string, string>, feature) => {
    if (params[feature.value]) {
      return {
        ...result,
        [feature.id]: params[feature.value],
      };
    }

    return result;
  }, {});
  if (!dataset.widgetType) {
    dataset.widgetType = 'comparison';
  }

  const hawkFallbackId = await generateDataHash({ ...dataset });
  setHawkFallbackId(el, hawkFallbackId);

  // Handle label filters differently as these are dynamic and therefore aren't in widgetFeatures
  return {
    ...dataset,
    ...getLabelDatasetParams(params),
    hawkFallbackId,
  };
};

export default async (selector?: string): Promise<UnbuiltPlaceholders> => {
  const elements = getNodes({ selector: selector || '#content' });
  const callbackToBuildPlaceholder = async ({
    element,
  }: BuildCallbackConfig): Promise<Array<HTMLElement | null>> => {
    const placeholderArray: Array<HTMLElement | null> = [];
    const result = await preparePlaceholders({
      dataset: await getCCDataset(element),
      node: element,
      position: 'before',
    });
    placeholderArray.push(result);

    return placeholderArray;
  };

  return {
    elements,
    callbackToBuildPlaceholder,
  };
};
